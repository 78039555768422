.book-img {
  width: 100%;
}

.column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid rgba(0, 0, 0, 1);
}
.row-container {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-between;
  text-align: start;
  /* width: 200px; */
  border: 2px solid rgba(255, 0, 0, 1);
  /* align-content: space-around; */
}

.left {
  flex: 1 1;
  /* align-items: center; */
  border: 2px solid rgba(255, 255, 0, 1);
  /* textAlign: left; */
}

.right {
  flex: 1 1;
  /* align-items: center; */
  border: 2px solid rgba(0, 255, 0, 1);
  /* margin-bottom: 2%; (100-32*3)/2 */
}

.bold {
  font-weight: bold;
}

.grey {
  background-color: #333;
}

.right:hover {
  background-color: #3e8e41;
  color: white;
}

.library {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}

.red-border {
  border: 1px solid rgba(255, 0, 0, 1);
}

.book {
  margin: 0px 50px;
  margin-bottom: 20px;
  width: 30;
  position: relative;
  z-index: 0;
  display: inline;
}

.book-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #444;
  opacity: 0.7;
  z-index: 1;
  color: rgb(102, 102, 245);
  text-align: center;
  width: 100%;
  transition: 0.4s;
  opacity: 0;
}
.book-footer.hover {
  opacity: 0.9;
}

.buy-link {
  color: white;
}
.buy-link:visited {
  color: grey;
}

.book-img {
  position: relative;
  z-index: 0;
  /* Drop extra space at bottom: https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image */
  vertical-align: middle;
}

.paper {
  text-align: "center";
  /* color: theme.palette.text.secondary; */
  background-color: #333;
  width: 100;
}
